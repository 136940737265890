<template>
  <!-- <header style="position:inherit !important;"> -->
  <header>
        <div>
            <div class="cabecera-opciones">
                <ul class="d-flex justify-content-between align-items-center">
                    <div >
                      <li class="d-flex">
                            <a href="http://www.ieebcs.org.mx">
                                <img                                                          
                                src="@/assets/ieebcs.png"
                                alt="logo prep generales"
                                class="cabecera-img-ieebcs"
                                >
                            </a>
                            <div class="pt-2 pl-3 hipervinculo">
                              <a href="http://www.ieebcs.org.mx" >http://www.ieebcs.org.mx</a>
                            </div>
                      </li>
                    </div>
                    <div class="encabezado">
                      <li>
                         <router-link class="encabezado" to="/base-datos">
                                    <font-awesome-icon icon="database" class="mr-3" />
                                    Base de datos
                          </router-link>
                    </li>
                    <li>
                        <font-awesome-icon icon="question-circle" />
                        <router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link>
                    </li>
                    <li>
                        <font-awesome-icon icon="info-circle" />
                        <router-link to="/centro-de-ayuda">
                          Centro de ayuda
                        </router-link>
                        <!-- <a href="https://ayuda.ine.mx/2018/PREP/" target="__blank">Centro de ayuda</a> -->
                    </li>
                    <li class="slidecontainer">
                        <zoom />
                    </li>
                    <li>
                        <modo-oscuro />
                    </li>
                    </div>
                </ul>
            </div>
            <div class="cabecera-titulo">
                <ul class="row mx-0">
                    <li class="col logo-ieebcs">
                        <div>
                            <img src="@/assets/logo-ieebcs.png" alt="logo IEEBCS" v-if="!esOscuro">
                            <img src="@/assets/ieebcs.png" alt="logo IEEBCS" v-else>
                        </div>
                    </li>
                    <li class="col-7">
                        <div>
                            <h1>Programa de Resultados
                              <span>E</span>lectorales Preliminares 2024
                            </h1>
                            <h5>Elecciones Estatales de <span>Baja California Sur</span></h5>
                        </div>
                    </li>
                    <li class="col logo-prep">
                        <div>
                            <img
                              v-if="!esOscuro"
                              src="@/assets/generales/PREP_2024.png"
                              alt="logo prep generales"
                            >
                            <img
                              v-else
                              src="@/assets/generales/prep-2024.png"
                              alt="logo prep generales"
                            >
                        </div>
                    </li>
                </ul>
            </div>
            <div class="barra-de-navegacion">
                <nav>
                    <ul>
                        <li class="me-3">
                            <div>
                              <div >
                                <router-link :to="cambiarVista('D')">
                                <font-awesome-icon icon="home" />
                                Inicio
                                </router-link>
                              </div>
                            </div>

                        </li>
                        <li v-if="gubernaturaE">
                            
                            <div :class="{ 'activo': eleccion === 'G' }">
                              <router-link :to="cambiarVista('G')">Gubernatura</router-link>
                            </div>
                            
                        </li>
                        <li>
                            <div
                              :class="{
                                'activo': eleccion === 'D' || esRepresentacionProporcional
                              }"
                            >
                                <router-link :to="cambiarVista('D')">Diputaciones</router-link>
                            </div>
                        </li>
                        <li>
                            <div :class="{ 'activo': eleccion === 'A' }">
                                <router-link :to="cambiarVista('A')">Ayuntamientos</router-link>
                            </div>
                        </li>
                        <li>
                          
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>
<style lang="scss" scoped>
.cabecera-opciones{
  // display: flex;
  .cabecera-img-ieebcs{
    width: 55px;
    height: 40px;
  }
  .hipervinculo{
    a{
      font-size: 14px; 
      text-decoration: none; 
      box-shadow: none;
      color: #f9fcff;     
    }
    a:hover {
      text-decoration: none!important; 
      box-shadow: none!important;; 
     
    }
  }
  img{
    width: 100px;
    
    // float: right;
    // margin-right: 700px;
    
  }
}

@media(max-width: 1300px) {
  .cabecera-opciones{
    max-height: 100px;
    .encabezado{
li{
  font-size: 10px;

}
    }
    .cabecera-img-ieebcs{
      width: 70px;
      height: 50px;
    }
    .hipervinculo{
      a{
        font-size: 5px!important; 
           
      }
      a:hover {
        text-decoration: none!important; 
        box-shadow: none!important;; 
      
      }
    }
   
   

  }
  .barra-de-navegacion{
    
          router-link{
            font-size: 5px!important;
          }
  }
      
  .cabecera-titulo{
      h1,h5{
        font-size: 5px!important;
      }
     
    }
}
</style>

<script>
import ModoOscuro from '@/components/ModoOscuro.vue';
import Zoom from '@/components/Movil/componentes/Zoom.vue';

export default {
  components: { ModoOscuro, Zoom },
  methods: {
    cambiarVista(tipoEleccion) {
      return `/${tipoEleccion}/ENT/VPC`;
    },
  },
  computed: {
    eleccion() {
      return this.$route.params.eleccion;
    },
    esOscuro() {
      return this.$store.state.esOscuro;
    },
    esRepresentacionProporcional() {
      return this.$router.currentRoute.name === 'RepresentacionProporcional';
    },
  },
  name: 'GubernaturaEleccion',
  data() {
    return {
      gubernaturaE: false,
    };
  },
  
};
</script>


