<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Actas en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                ¿Cómo consultar la imagen del Acta PREP?
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            ¿Cómo consultar la imagen del Acta PREP?
          </h2>

          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-12 text-center">
              <div _ngcontent-qrr-c69="" class="col-lg-12 video">
                <b-embed
                  type="video"
                  aspect="16by9"
                  controls
                >
                  <source
                    src="https://ayuda.ine.mx/2024/informate/assets/video/Como consultar un Acta PREP_VF.mp4"
                    type="video/mp4"
                  >
                </b-embed>
              </div>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 list-stilo m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-1.svg" alt=""></div>
            <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
              <p _ngcontent-qrr-c69="" class="f-s-24 f-w-500"> ¿Cómo consultar un Acta PREP en el sitio? </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-12">
              <p _ngcontent-qrr-c69="" class="f-s-24"> Puedes consultar la imagen del Acta PREP en equipos de cómputo y en dispositivos móviles.​ </p>
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Equipos de cómputo </h2>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">1 </b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> En el apartado “Conoce los resultados de tu Sección”, ingresa tus datos y da clic en “Consulta”.&nbsp; </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-1.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-6 rsp-pt60">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">a</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Te redirige a la sección que ingresaste. </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">b</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Selecciona la casilla donde votaste y da clic sobre esta para ir al apartado de tu casilla. ​ </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row rsp-pt30 rsp-pb40">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-2.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">2</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> En el menú “Consulta de votación”, selecciona la Circunscripción, Entidad, Distrito, Sección y la Casilla que buscas. </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-3.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">3</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Para consultar “Votos en Prisión Preventiva”, da clic en Circunscripción, Entidad, Distrito, Sección y selecciona su apartado. </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-6 text-center align-self-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-4.svg" class="img-fluid img-responsive"></div>
                <div _ngcontent-qrr-c69="" class="col-lg-6 align-self-end">
                  <p _ngcontent-qrr-c69="" class="f-s-16"> Nota: Solo aplica a las Entidades Sonora, Guanajuato, Chiapas, Morelos y Michoacán. </p>
                </div>
              </div>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
              <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">4</b></p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
              <p _ngcontent-qrr-c69="" class="f-s-24"> En los tres casos, identifica el apartado “Votos en Actas Contabilizadas”.​ </p>
              <p _ngcontent-qrr-c69="" class="f-s-24"><b _ngcontent-qrr-c69="" class="clr-cmpt">a.</b> Da clic en el botón “Ver” y consulta la imagen del Acta PREP. </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-5.svg" class="img-fluid img-responsive"></div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-12">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Dispositivo móvil </h2>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">1</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Presiona “Menú”, selecciona “Sección”, ingresa los datos y da clic en “Consulta”.​ </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-6.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">a</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Te redirige a la sección que ingresaste. ​ </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">b</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Selecciona la casilla donde votaste y da clic sobre esta para ir al apartado de tu casilla. </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-7.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">2</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Selecciona la Circunscripción, Entidad, Distrito, Sección y la Casilla que buscas. </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-8.svg" class="img-fluid img-responsive"></div>
              </div>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-6">
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
                  <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">3</b></p>
                </div>
                <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
                  <p _ngcontent-qrr-c69="" class="f-s-24"> Para consultar “Votos en Prisión Preventiva”, selecciona en Circunscripción, Entidad, Distrito, Sección y su apartado. ​ </p>
                </div>
              </div>
              <div _ngcontent-qrr-c69="" class="row">
                <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40">
                  <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-9.svg" class="img-fluid img-responsive">
                  <p _ngcontent-qrr-c69="" class="f-s-24 m-t-20"> Nota: Solo aplica a las Entidades Sonora, Guanajuato, Chiapas, Morelos y Michoacán.​ </p>
                </div>
              </div>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r">
              <p _ngcontent-qrr-c69=""><b _ngcontent-qrr-c69="" class="clr-cmpt">4</b></p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
              <p _ngcontent-qrr-c69="" class="f-s-24"> En los tres casos, identifica el apartado “Votos en Actas Contabilizadas”.​ </p>
              <p _ngcontent-qrr-c69="" class="f-s-24"><b _ngcontent-qrr-c69="" class="clr-cmpt">a.</b> Da clic en el botón y consulta la imagen del Acta PREP. </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-12 text-center rsp-pt30 rsp-pb40"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-10.svg" class="img-fluid img-responsive"></div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-1 col-1 offset-1 offset-lg-0 imagenes-50 text-right sn-p-r"><img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-2.svg" class="img-fluid img-responsive"></div>
            <div _ngcontent-qrr-c69="" class="col-lg-11 col-10">
              <p _ngcontent-qrr-c69="" class="f-s-24 f-w-500"> Iconografía del medio de digitalización del Acta PREP </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-12">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Indica si el Acta: </h2>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-t-20">
            <div _ngcontent-qrr-c69="" class="col-lg-3 text-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-11.svg" class="img-fluid img-responsive">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Acta en Proceso </h2>
              <p _ngcontent-qrr-c69="" class="f-s-16"> Está en proceso </p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-3 text-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-12.svg" class="img-fluid img-responsive">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Escáner </h2>
              <p _ngcontent-qrr-c69="" class="f-s-16"> Fue digitalizada con escáner </p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-3 text-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-13.svg" class="img-fluid img-responsive">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Móvil </h2>
              <p _ngcontent-qrr-c69="" class="f-s-16"> Fue digitalizada con dispositivo móvil </p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-3 text-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-14.svg" class="img-fluid img-responsive">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Urna Electrónica </h2>
              <p _ngcontent-qrr-c69="" class="f-s-16"> Proviene de una Casilla con Urna Electrónica​ </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-12">
              <h2 _ngcontent-qrr-c69="" class="f-s-sub"> Notas: </h2>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30">
            <div _ngcontent-qrr-c69="" class="col-lg-6 text-center align-self-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-15.svg" class="img-fluid img-responsive">
              <p _ngcontent-qrr-c69="" class="f-s-24 text-left"> El Acta PREP de Votos en Prisión Preventiva<sup _ngcontent-qrr-c69="">1</sup> es digitalizada solo en escáner. </p>
            </div>
            <div _ngcontent-qrr-c69="" class="col-lg-6 text-center rsp-pt30 rsp-pb40">
              <img _ngcontent-qrr-c69="" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-imagen-16.svg" class="img-fluid img-responsive">
              <p _ngcontent-qrr-c69="" class="f-s-24 text-left"> Urna Electrónica<sup _ngcontent-qrr-c69="">2</sup> solo aplica a las Entidades de Jalisco y Coahuila, el Acta que se muestra es una representación gráfica de ésta. </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-b-50">
            <div _ngcontent-qrr-c69="" class="col-lg-12 f-s-16">
              <p _ngcontent-qrr-c69="" class="f-w-500"> Las imágenes utilizadas en esta infografía son representativas.&nbsp; </p>
            </div>
          </div>
          <div _ngcontent-qrr-c69="" class="row p-contenedor-30 m-b-50">
            <div _ngcontent-qrr-c69="" class="col-lg-12 text-center f-s-16">
              <p _ngcontent-qrr-c69="" class="f-w-500"> Referencias: </p>
              <p _ngcontent-qrr-c69="" class="referencias"> · 1 Acuerdo INE/CG97/2024 del Consejo General del Instituto Nacional Electoral por el que se aprueba el Modelo de Operación del Voto de las Personas en Prisión Preventiva, para el Proceso Electoral 2023 - 2024, en acatamiento a la sentencia dictada en el expediente sSUP-JDC-352/2018 y acumulado. </p>
              <p _ngcontent-qrr-c69="" class="referencias"> · 2 Modelo de Operación de la casilla con urna electrónica para los Procesos Electorales Locales de Coahuila e Hidalgo 2019-2020. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Como_Consultar_la_Imagen_del_Acta_PREP.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
